@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    min-height: 100%;
}

@layer base {
    body {
        @apply bg-slate-100;
    }

    a {
        @apply text-blue-900;
        @apply underline;
    }
}

@media only screen and (max-width: 576px) {
    body {
        font-size: 14px;
    }
}

.ck-editor__editable:not(.ck-editor__nested-editable) {
    min-height: 120px;
}

.ck-editor ul, .cs-message ul {
    @apply !list-disc !ml-4 !pl-4 mt-3;
}

.ck-editor ol, .cs-message ol {
    @apply !list-decimal !ml-4 !pl-4 mt-3;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
    @apply bg-slate-200;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
    @apply bg-blue-500;
    color: #fff;
}

.cs-message-list__scroll-wrapper > .cs-message-group:nth-last-child(4) {
    margin-bottom: 1.5em;
}

.cs-message-group--custom .cs-message-group__messages .cs-message .cs-message__content {
    background: #fff;
    padding: 0;
}

.cs-message-input__content-editor-wrapper {
    @apply border-slate-400 border;
    background-color: #fff;
}

.cs-message-input__content-editor {
    background-color: #fff;
}

.cs-button--send {
    @apply text-blue-800;
}

.cs-chat-container {
    padding-top: 15px;
}

.cs-chat-container .cs-message-input {
    @apply border-0;
    padding-bottom: .8em;
}

.cs-main-container .cs-chat-container {
    @apply border-0;
}

.cs-main-container {
    @apply border-0;
}

.react-datepicker--time-only .react-datepicker__time-container {
    width: 120px !important;
}